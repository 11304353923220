.container {
    background-color : black;
    display          : flex;
    flex-direction   : row;
    height           : 30px;
    justify-content  : space-between;
    padding          : 10px;
    width            : 180px;
}

.wrapper {
    color           : white;
    display         : flex;
    flex-direction  : column;
    font-size       : 0.8em;
    justify-content : center;
}

#github-logo {
    filter : invert();
    height : 20px;
    width  : 20px;
}
