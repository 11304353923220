.container {
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    height          : 100%;
    justify-content : flex-end;
    width           : 300px;
}

.notification-button {
    margin-right : 10px;
}

.notification-text {
    font-size : 1.25rem;
}

.notification-icon {
    height         : 100%;
    margin-right   : 10px;
    vertical-align : -32%;
    width          : 30px;
}

.text-wrapper {
    display : inline-block;
    height  : 100%;
}
