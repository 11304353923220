.container {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    height          : 100%;
    justify-content : center;
    padding         : 10px;
    width           : 100%;
}

.top-bar, .list, .playlist {
    width : 96%;
}

.top-bar {
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    font-size       : 1.25rem;
    justify-content : space-between;
}

.button-add {
    margin-left : 20px;
}

.button-add-icon {
    height : 25px;
    width  : 25px;
}

.search-bar {
    width : 20vw;
}

.playlist {
    flex-grow  : 1;
    margin-top : 10px;
    overflow-y : scroll;
    padding    : 10px;
}
