.container {
    background-color : rgba(0, 0, 0, 0.2);
    display          : flex;
    flex-direction   : row;
    height           : 100%;
    width            : 350px;
}

.album-icon-wrapper {
    align-items      : center;
    background-color : rgba(0, 0, 0, 0.2);
    display          : flex;
    height           : 100%;
    justify-content  : center;
    width            : 60px;
}

.album-icon-placeholder {
    color  : white;
    height : 50%;
    width  : 50%
}

.song-info-text-box {
    color           : white;
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    margin-left     : 20px;
}

.song-title {
    font-size : 1.25rem;
}

.song-artist {
    font-size : 0.85rem;
}
