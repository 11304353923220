.container {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    height          : 100%;
    justify-content : center;
    padding         : 10px;
    width           : 100%;
}

.top-bar, .list {
    width : 96%;
}

.top-bar {
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    font-size       : 1.25rem;
    justify-content : space-between;
}

.list {
    display        : flex;
    flex-direction : column;
    height         : 100%;
    margin         : 10px;
    overflow-y     : scroll;
    padding        : 5px 10px 5px 10px;
}
