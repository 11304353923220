.nav {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between !important;
    padding         : 5px 20px 5px 20px;
    width           : 100%;
}

.justify-left {
    color           : white;
    display         : flex;
    flex-direction  : row;
    height          : 100%;
    justify-content : flex-start;
}

.justify-right {
    color           : white;
    display         : flex;
    flex-direction  : row;
    height          : 100%;
    justify-content : flex-end;
}

.location-indicator {
    display         : flex;
    flex-direction  : column;
    justify-content : flex-end;
    padding-left    : 20px;
}

.hamburger {
    margin-right : 20px;
}

.user-icon {
    height : 100%;
    width  : 40px;
}

.leave-room-button {
    margin : 0 20px 0 20px;
}

.leave-room-icon {
    height         : 100%;
    margin-right   : 10px;
    vertical-align : center;
    width          : 30px;
}

.brand {
    font-size   : xx-large !important;
    font-weight : 600;
}
