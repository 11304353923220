.container {
    display         : flex !important;
    flex-direction  : row;
    justify-content : space-between;
    width           : 100%;
}

.left, .right {
    align-items    : center;
    display        : flex;
    flex-direction : row;
}

.left {
    justify-content : flex-start;
}

.right {
    justify-content : flex-end;
}

.list-icons {
    height : 60px;
    width  : 60px;
}

.song-info-text-box {
    display         : flex;
    flex-direction  : column;
    justify-content : center;
    margin-left     : 20px;
}

.song-title {
    font-size : 1.375rem;
}

.song-artist {
    font-size : 1rem;
}

.control-bar-button-icons {
    margin-right   : 10px;
    vertical-align : -8%;
}

.text {
    font-size : 1.25rem;
    margin    : 0 10px 0 3vw;
}
