.container {
    align-items    : center;
    display        : flex;
    flex-direction : column;
    height         : 100%;
    padding        : 0 120px 0 120px;
}

.title {
    align-self : flex-start;
}

.title > h1, .title > h5 {
    line-height : 60px;
}

.button-group-wrapper {
    height : 100%;
}

.button-group {
    display         : flex;
    flex-direction  : column;
    height          : 100%;
    justify-content : center;
    margin-top      : -15%;
    width           : 300px;
}

.button-group > button {
    font-size : x-large;
    margin    : 20px 0 20px 0;
    padding   : 30px 10px 30px 10px;
}
