.container {
    align-items     : center;
    display         : flex;
    flex-direction  : column;
    height          : 100%;
    justify-content : space-evenly;
}

.code-box {
    width : 600px;
}

.code-box-input {
    font-family    : source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;;
    font-size      : 100px !important;
    height         : 200px;
    letter-spacing : 0.2em;
    padding-left   : 0.4em !important;
    text-align     : center;
}

.button-group {
    display         : flex;
    flex-direction  : row;
    justify-content : space-evenly;
    width           : 100%;
}

.button-group > button {
    font-size : x-large;
    margin    : 20px 0 20px 0;
    padding   : 30px 100px 30px 100px;
}
