.container {
    width : 500px;
}

.card {
    padding : 40px;
}

.center-wrapper {
    display         : flex;
    justify-content : center;
    margin-top      : 40px;
}

