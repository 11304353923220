.container {
    display         : flex;
    flex-direction  : column;
    height          : 100vh;
    justify-content : space-between;
    overflow        : hidden;
    padding-bottom  : 10px;
}

.top-bar {
    display         : flex;
    flex-direction  : row;
    justify-content : space-between;
}

.main {
    height : 100%;
}

.footer {
    text-align : center;
}

.anchor {
    text-decoration : none;
}

.github-badge-wrapper {
    block-size : fit-content;
    cursor     : pointer;
    width      : fit-content;
}
