@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";

body {
    font-family             : "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing  : antialiased;
    margin                  : 0;
    -moz-osx-font-smoothing : grayscale;
}

code {
    font-family : source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

