.container {
    align-items     : center;
    display         : flex;
    flex-direction  : row;
    justify-content : center;
    margin          : 20px 30px 20px 30px;
}

.button {
    margin : 0 20px 0 20px;
}

.language-icon {
    height : 25px;
    width  : 25px;
}
